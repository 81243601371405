import Link from 'next/link'
import { LegoSet, ThemeSummary } from '../../model/legoSet'

type Props = {
  themeSummary: ThemeSummary
}

export default function ThemeCard({ themeSummary }: Props) {
  return (
    <Link href={{ pathname: '/sets', query: { theme: themeSummary.id } }}>
      <div className="group relative">
        <div className="aspect-w-4 aspect-h-2 overflow-hidden rounded-lg">
          <img
            src={
              themeSummary.themeImgUrl
            }
            alt={themeSummary.name}
            className="object-contain object-center"
          />
          <div
            className="flex items-end p-4 opacity-0 group-hover:opacity-100"
            aria-hidden="true"
          ></div>
        </div>
        <div className="mt-4 flex items-center justify-between space-x-8 text-base font-medium text-gray-900">
          <h3>
            <span aria-hidden="true" className="absolute inset-0" />
            {themeSummary.name}
          </h3>
          <p>{themeSummary.numberOfSets}</p>
        </div>
      </div>
    </Link>
  )
}
