import { LegoSet } from '../model/legoSet'
import { LinkIcon, PlusIcon, ShoppingCartIcon } from '@heroicons/react/20/solid'
import { createImageUrl } from '../utils/image-helpers'

interface Props {
  legoset: LegoSet
}

export default function ItemCard({ legoset }: Props) {
  return (
    <li
      key={legoset.setNum}
      className="col-span-1 divide-y divide-gray-200 rounded-lg bg-white text-center shadow"
    >
      <a className="flex flex-col" href={'/sets/' + legoset.setNum}>
        <div className="flex flex-1 flex-col p-8">
          <img
            className="mx-auto h-32 w-32 flex-shrink-0"
            src={createImageUrl(250, legoset.setImgUrl)}
            alt=""
          />
          <h3 className="mt-6 text-sm font-medium text-gray-900">
            {legoset.name}
          </h3>
          <dl className="mt-1 flex flex-grow flex-col justify-between">
            <dt className="sr-only">Rating</dt>
            <dd className="mt-3 flex justify-center text-sm text-gray-500">
              <div className="flex flex-col items-center">
                <div className="flex items-baseline text-2xl font-semibold text-indigo-600">
                  € {legoset.amount.toFixed(2)}
                </div>
                <div>{legoset.shopName}</div>
              </div>
            </dd>
          </dl>
        </div>
      </a>
    </li>
  )
}
