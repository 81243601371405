import Head from 'next/head'

type Props = {
  title: string
}

export default function PPHead(props: Props) {
  return (
    <Head>
      <title>{props.title}</title>
      <meta name='description' content='Pricepatrol' />
      <meta name='viewport' content='width=device-width, initial-scale=1' />
      <link rel='icon' href='/favicon.ico' />
    </Head>
  )
}
