import { Cloudinary } from '@cloudinary/url-gen'
import dayjs from 'dayjs'
import { fill } from '@cloudinary/url-gen/actions/resize'

const cld = new Cloudinary({
  cloud: {
    cloudName: 'dhodfpv4c',
  },
})

export function createImageUrl(width: number, publicId?: string): string {
  if (publicId) {
    return cld
      .image(publicId)
      .resize(fill().width(width))
      .toURL()
  }
  return `/img/placeholder.jpg`;
}
