import Link from 'next/link'
import { useEffect, useState } from 'react'
import StackedLayout from '../components/layout/stacked-layout'
import { LegoSet, LegoSetResponse, ThemeSummary } from '../model/legoSet'
import ThemeCard from '../components/themes/theme-card'
import PPHead from '../components/head/head'
import ItemCard from '../components/item-card'

const incentives = [
  {
    name: 'Overzicht',
    imageSrc: '/img/overview.svg',
    description:
      'Op onze website vind je een uitgebreid overzicht van de nieuwste en populairste Lego-sets, zodat je altijd op de hoogte bent van de laatste ontwikkelingen op het gebied van Lego-bouwsets.',
  },
  {
    name: 'Prijzen vergelijken',
    imageSrc: '/img/price.svg',
    description:
      'Met onze prijsvergelijkingstool kun je gemakkelijk de prijzen van Lego-sets vergelijken bij verschillende retailers, zodat je altijd de beste prijs krijgt voor het product dat je wilt kopen.',
  },
  {
    name: 'Deals',
    imageSrc: '/img/deals.svg',
    description:
      'Op onze website vind je de beste deals en aanbiedingen voor Lego-sets, waardoor je kunt besparen op je aankopen.',
  },
]

export default function Home() {
  const [data, setData] = useState<ThemeSummary[]>([])
  const [newData, setNewData] = useState<LegoSet[]>([])

  useEffect(() => {
    loadNew()
    loadThemes()
  }, [])

  function loadThemes() {
    const url = `${
      process.env.NEXT_PUBLIC_API_URL
    }/api/themes/random/3`

    fetch(url, {})
      .then((res) => res.json())
      .then((data: ThemeSummary[]) => {
        setData(data)
      })
  }

  function loadNew() {
    const params = new URLSearchParams()
    params.append('size', '3')
    params.append('addedSinceDays', '30')
    const url = `${
      process.env.NEXT_PUBLIC_API_URL
    }/api/sets?${params.toString()}`

    fetch(url, {})
      .then((res) => res.json())
      .then((data: LegoSetResponse) => {
        setNewData(data.content)
      })
  }

  return (
    <>
      <PPHead title={'Pricepatrol - Home'} />

      <main>
        <StackedLayout current={''}>
          <>
            <div>
              <div className="mx-auto max-w-7xl pb-12 sm:px-2 sm:pb-32 lg:px-4">
                <div className="mx-auto max-w-2xl px-4 lg:max-w-none">
                  <div className="grid grid-cols-1 items-center gap-y-10 gap-x-16 lg:grid-cols-2">
                    <div>
                      <h2 className="text-4xl font-bold tracking-tight text-gray-900">
                        Vergelijk prijzen en doe je voordeel
                      </h2>
                      <p className="mt-4 text-gray-500">
                        {' '}
                        Of je nu op zoek bent naar een nieuwe set of een cadeau
                        voor een Lego-liefhebber, door de prijzen te vergelijken
                        kun je zeker je voordeel doen en mogelijk geld besparen.
                      </p>
                    </div>
                    <div className="aspect-w-3 aspect-h-2 overflow-hidden rounded-lg bg-gray-100">
                      <img
                        src="/img/21339-Boom-202303-PS-Hero-Standard-Large.webp"
                        alt=""
                        className="object-cover object-right"
                      />
                    </div>
                  </div>
                  <div className="mt-16 grid grid-cols-1 gap-y-10 gap-x-8 lg:grid-cols-3">
                    {incentives.map((incentive) => (
                      <div key={incentive.name} className="sm:flex lg:block">
                        <div className="sm:flex-shrink-0">
                          <img
                            className="h-16 w-16"
                            src={incentive.imageSrc}
                            alt=""
                          />
                        </div>
                        <div className="mt-4 sm:mt-0 sm:ml-6 lg:mt-6 lg:ml-0">
                          <h3 className="text-sm font-medium text-gray-900">
                            {incentive.name}
                          </h3>
                          <p className="mt-2 text-sm text-gray-500">
                            {incentive.description}
                          </p>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>

            <div className="bg-white">
              <div className="mx-auto max-w-2xl py-8 px-4 sm:py-24 sm:px-6 lg:max-w-7xl lg:px-8">
                <div className="flex items-center justify-between space-x-4">
                  <h2 className="text-lg font-medium text-gray-900">Nieuw</h2>
                  <Link
                    href="/new"
                    className="whitespace-nowrap text-sm font-medium text-indigo-600 hover:text-indigo-500"
                  >
                    Alles tonen
                    <span aria-hidden="true"> &rarr;</span>
                  </Link>
                </div>
                <ul className="mt-6 grid grid-cols-1 gap-x-8 gap-y-2 sm:grid-cols-2 sm:gap-y-10 lg:grid-cols-3">
                  {newData.map((set, id) => (
                    <ItemCard key={id} legoset={set} />
                  ))}
                </ul>
              </div>
            </div>

            <div className="bg-white">
              <div className="mx-auto max-w-2xl py-8 px-4 sm:py-24 sm:px-6 lg:max-w-7xl lg:px-8">
                <div className="flex items-center justify-between space-x-4">
                  <h2 className="text-lg font-medium text-gray-900">
                    Thema&apos;s
                  </h2>
                  <Link
                    href="/themes"
                    className="whitespace-nowrap text-sm font-medium text-indigo-600 hover:text-indigo-500"
                  >
                    Alles tonen
                    <span aria-hidden="true"> &rarr;</span>
                  </Link>
                </div>
                <div className="mt-6 grid grid-cols-1 gap-x-8 gap-y-2 sm:grid-cols-2 sm:gap-y-10 lg:grid-cols-3">
                  {data.map((themeSummary) => (
                    <ThemeCard
                      key={themeSummary.id}
                      themeSummary={themeSummary}
                    />
                  ))}
                </div>
              </div>
            </div>
          </>
        </StackedLayout>
      </main>
    </>
  )
}
